<template>
    <div :id="modalId" class="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-body tx-center pd-y-20 pd-x-20">
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                        <span aria-hidden="true">×</span>
                    </button>
                    <i :class="computedType.icon"></i>
                    <h4 class="tx-indigo mg-b-20">{{computedType.text}}</h4>
                    <p class="mg-b-20 mg-x-20">{{message}}</p>
                    <button @click="onclick" class="mg-t-5 btn rounded btn-az-primary pd-x-25" data-dismiss="modal" type="button">
                        {{buttonText}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ConfirmModal",
        props: ["modalId", "type", "message", "buttonText", "onclick"],
        computed: {
            computedType: function () {
                if (this.type) {
                    switch (this.type) {
                        case "warning":
                            return {
                                icon: "ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block",
                                text: "Warning!"
                            };
                            break;
                        default:
                        case "alert":
                            return {
                                icon: "ion-ios-alert tx-100 tx-indigo lh-1 mg-t-20 d-inline-block",
                                text: "Alert!"
                            };
                            break;
                    }
                } else {
                    return {
                        icon: "ion-ios-alert tx-100 tx-indigo lh-1 mg-t-20 d-inline-block",
                        text: "Alert!"
                    };
                }
            }
        },
    }
</script>

<style scoped>
</style>