window.$ = window.jQuery = require('jquery');

import 'jquery';
import 'popper.js';
import 'bootstrap';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueFeather from 'vue-feather';
import axios from 'axios';
import VeeValidate from 'vee-validate';
import VueToastr from 'vue-toastr';
import moment from "moment";
import VueClipboard from 'vue-clipboard2';
import VueGeolocation from 'vue-browser-geolocation';
import _ from "lodash";
import VueApexCharts from 'vue-apexcharts';
import VMdDateRangePicker from "v-md-date-range-picker";

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

import timezones from './assets/data/timezones';
import StandardPage from '@/components/StandardPage';
import MobilePage from '@/components/MobilePage';
import ExtrinsicLink from '@/components/ExtrinsicLink';
import DataTable from '@/components/DataTable';
import DeleteModal from '@/components/DeleteModal';
import ConfirmModal from '@/components/ConfirmModal';

require("./assets/lib/@fortawesome/fontawesome-free/css/all.min.css");
require("./assets/lib/ionicons/css/ionicons.min.css");
require("./assets/lib/jqvmap/jqvmap.min.css");
require("./assets/css/vue-multiselect.css");
require("./assets/lib/typicons.font/typicons.css");
require("./assets/css/azia.css");
require("./assets/css/custom.css");
require("./assets/lib/spectrum-colorpicker/spectrum.css");
require("./assets/lib/ion-rangeslider/css/ion.rangeSlider.css");
require("./assets/lib/ion-rangeslider/css/ion.rangeSlider.skinFlat.css");
require("./assets/lib/amazeui-datetimepicker/css/amazeui.datetimepicker.css");
require("./assets/lib/jquery-simple-datetimepicker/jquery.simple-dtpicker.css");
require("./assets/lib/pickerjs/picker.min.css");

require("./assets/lib/jquery-steps/jquery.steps.min");
require("./assets/lib/jquery.flot/jquery.flot.js");
require("./assets/lib/jquery.flot/jquery.flot.resize.js");
require("./assets/lib/peity/jquery.peity.min.js");
require("./assets/lib/ionicons/ionicons.js");
require("./assets/lib/jquery-steps/jquery.steps.min");
require("./assets/js/azia.js");
require("./assets/js/chart.flot.sampledata.js");
require("./assets/lib/amazeui-datetimepicker/js/amazeui.datetimepicker.min.js");
require("./assets/lib/jquery-ui/ui/widgets/datepicker.js");
require("./assets/lib/jquery.maskedinput/jquery.maskedinput.js");
require("./assets/lib/spectrum-colorpicker/spectrum.js");
require("./assets/lib/select2/js/select2.min.js");
require("./assets/lib/ion-rangeslider/js/ion.rangeSlider.min.js");
require("./assets/lib/jquery-simple-datetimepicker/jquery.simple-dtpicker.js");
require("./assets/lib/pickerjs/picker.min.js");
require("./assets/lib/datatables.net/js/jquery.dataTables.min");

Vue.config.productionTip = false;

Vue.prototype.$ACCOUNTS_URL = process.env.VUE_APP_ACCOUNTS_URL_MAYA;
Vue.prototype.$IDENTITY_URL = process.env.VUE_APP_IDENTITY_URL;
Vue.prototype.$ASSET_REGISTRY_URL = process.env.VUE_APP_ASSET_REGISTRY_URL;
Vue.prototype.$PROVENANCE_URL = process.env.VUE_APP_PROVENANCE_URL;
Vue.prototype.$SITE_URI = process.env.VUE_APP_SITE_URI;
Vue.prototype.$BORLAUG = process.env.VUE_APP_BORLAUG;
Vue.prototype.$BLOCK_EXPLORER = process.env.VUE_APP_BLOCK_EXPLORER_MAYA;
Vue.prototype.$PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
Vue.prototype.$PRICE_ID = process.env.VUE_APP_STRIPE_PRICE_ID;

Vue.prototype.$DEFINITIONS = {
    identity: {
        toasts: {
            createCatalog: `Catalog successfully created`,
            updateCatalog: `Catalog successfully updated`,
            renameDid: `Did Label updated`,
            createDid: `DID successfully created on chain.`,
        }
    },
    catalog: `A <b>Catalog</b> is a collection of <b>Decentralized Identifiers (DIDs)</b>.`,
    newCatalog: `Create a new Catalog by providing a suitable name`,
    claim: `A <b>claim</b> is a statment about the <b>subject</b> of a <b>Decentralized Identifier (DID)</b>. It should be <b>attested</b> by a trusted third party.`,
    did: `<b>Decentralized Identifiers (DIDs)</b> are globally unique identifiers. They enable identity owners to generate, present, and verify <b>claims</b>. This forms the basis of trust between users of the systems.`,
    didDocument: `The <b>DID Document</b> is a collection of <b>properties</b> related to the identity.`,
    newDid: `Create a new <b>Decentralized Identifier (DID)</b> and its corresponding <b>DID Document</b> which consists of a collection of <b>properties</b> related to the identity.`,
    didController: `A <b>controller</b> is a person or entity that has permissions to manage a <b>Decentralized Identifier (DID)</b> on behalf of its <b>subject</b>`,
    claimConsumer: `A claim <b>consumer</b> is an entity that wishes to know a fact about a <b>Decentralized Identifier (DID)</b>. They make a claim against the DID which is then attested by a claim <b>issuer</b>`,
    claimIssuer: `A <b>claim issuer</b> can attest claims made about a <b>Decentralized Identifier (DID)</b>.`,
    claimRequests: `<b>Decentralized Identifiers (DIDs)</b> for which you have been requested to make a <b>claim</b> against.`,
    claimAttachment: `Supporting documents which proofs the <b>Claim</b> is valid`,
    claimAttestation: `Verifications made on the <b>Claims</b> to validate it`,
    attestationRequests: `<b>Decentralized Identifiers (DIDs)</b> with <b>claims</b> which you have been requested to <b>attest</b>.`,
    attestationAssertion: `I hereby attest that all the statements in this claim are true and correct.`,
    provenance: {
        about: `<b>Provenance</b> is the record of a process. This could be a process whereby goods are manufactured/bought/sold etc, or a purely buisness process not involving any physical goods. 
            At each step of the process an assigned <b>attestor</b> must certify that the step has been completed and provide some information (<b>Attributes</b>) 
            about the outcome of that step such as the quantity of goods produced for example.</b>)`,
        registry: `<b>Process Definitions</b> are organized into <b>Registries</b> to make them easier to manage.`,
        newRegistry: `Create a new Registry by providing a suitable name`,
        processDefinition: `A <b>Process Definition</b> defines the set of <b>steps</b> that make up a process and the <b>Decentralized Identifiers (DIDs)</b> of the <b>Attestors</b> that are authorized to attest to the completion of a given step.`,
        newProcessDefinition: ``,
        newProcessDefinitionStep: `Create a new Process Step by providing a suitable name`,
        attestor: `<b>Attestors</b> who sign of the <b>process steps</b> provide a set of statements or facts about the step and attest that they are accurate`,
        addAttestor: `Add new attestor to a specific process step by proving <b>Decentralized Identifiers (DIDs)</b>`,
        process: `A <b>Process</b> consists of a set of <b>steps</b>. When each step of the process is completed, it must be signed off by any of the designated <b>Attestors</b> who provide a set of statements or facts about the step and attest that they are accurate.`,
        ongoingProcess: `These are ongoing processes for which you are an attestor on at least one step.`,
        completeProcess: `These are the completed <b>Proceses</b> for which you are an <b>Attestor</b> on at least one step.`,
        pending: `These are ongoing <b>Proceses</b> for which you are an <b>Attestor</b> for the current step.`,
        existingProcess: `Existing processes based on this <b>Process Definition</b> `,
        newProcess: ``,
        processSteps: `A <b>Process</b> consists of a set of <b>steps</b>. It must be signed off by any of the designated <b>Attestors</b> who provide a set of statements or facts about the step and attest that they are accurate.`,
        processStep: `A <b>Process step</b> must be signed off by any of the designated <b>Attestors</b> who provide a set of statements or facts about the step and attest that they are accurate.`,
        processStepAttributes: `<b>Attributes</b> are a collection of name/value pairs that are recorded by the <b>Attestor</b> and attested to be accurate.`,
        availableProcess: `As an attestor for the first step of these <b>Process Definitions</b> it is your reponsibility to create new <b>process</b> as and when required.`,
        addAttribute: `Add new properties into process step`,
        editAttribute: `update the attribute`,
        pickup: `Pick up commodity at <b>Farming community</b>`,
        receive: `Receive commodity at <b>Distribution centre</b>`
    },
    admin: {
        about: `Manage platform shared resources`,
        subscription: `<b>Services</b> which the current organization is subscribed for`,
        newSubscription: `Subscribe to the new <b>Services</b>`,
        orgs: `List of <b>Organizations</b> registered in <b>BWS</b>`,
        members: `Persons belonging to the <b>Organization</b>`,
        currencies: `Manage currencies`,
        units: `Manage units of measure`,
        taxes: `Manage taxes`,
        rates: `Manage tax rates`,
        permissions: `Permissions used for security management`,
        roles: `Roles used for security management`
    }
};
Vue.use(VueGeolocation);

function truncate(text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
}


Vue.prototype.$ExtrinsicLink = (extrinsic) => {
    if (extrinsic) {
        return `<a href="${Vue.prototype.$BLOCK_EXPLORER}/tx/${extrinsic}" target="_blank"><i class="fa fa-link"></i>&nbsp;${truncate(extrinsic, 6)}</a>`;
    } else {
        return '';
    }
};

Vue.prototype.$DidLink = (did) => {
    if (did) {
        return `<a
            title="${did}"
            data-did="${did}"
            href="javascript:void(0);"
            onclick="clipboard_copy(this)"      
        >
        did:bws:${truncate(did.substring(2), 10)}
      </a>`;
    } else {
        return '';
    }
};

Vue.prototype.$StandardButtons = {
    deleteButton: (target, title, label) => {
        title = title ? title : 'Delete';
        label = label ? label : 'Delete';
        return `<span class="text-decoration-none text-danger delete" data-target="${target}" data-toggle="modal" style="cursor: pointer">
                    <i class="fas fa-trash-alt fa-lg" title="${title}"></i>
                </span>`;
    },
    renameButton: (title, label) => {
        title = title ? title : 'Rename';
        label = label ? label : 'Rename';
        return `<span
               class="text-info rename mg-r-10" style="cursor: pointer"
               title="${title}">
               <i class="fa fa-edit fa-lg"></i>
            </span>`;
    },
    attestButton: (target, title, label) => {
        title = title ? title : 'Attest';
        label = label ? label : 'Attest';
        return `<span data-target="${target}" data-toggle="modal"
        class="text-info bg-white fa-border text-decoration-none attest mg-r-5"  style="cursor: pointer">
        <i class="fa fa-signature fa-lg" title="${title}"></i> ${label}
        </span>`;
    },
    upButton: (title) => {
        title = title ? title : 'Up';
        return `<span
        class="text-info up mg-r-10"  style="cursor: pointer">
        <i class="fas fa-arrow-up fa-lg" title="${title}"></i>
        </span>`;
    },
    downButton: (title) => {
        title = title ? title : 'Down';
        return `<span
        class="text-info down mg-r-10"  style="cursor: pointer">
        <i class="fas fa-arrow-down fa-lg" title="${title}"></i>
        </span>`;
    },
    editButton: (title, label) => {
        title = title ? title : 'Edit';
        label = label ? label : 'Edit';
        return `<span
               class="text-info text-decoration-none edit mg-r-10" style="cursor: pointer"
               title="${title}">
               <i class="fa fa-edit fa-lg"></i>
            </span>`;
    },
    viewTemplateButton: (title, label) => {
        title = title ? title : 'View Template';
        label = label ? label : 'Templates';
        return `<span
               class="text-primary view-template mg-l-10" style="cursor: pointer"
               title="${title}">
               <i class="fa fa-clipboard fa-lg"></i>
            </span>`;
    },
};


Vue.use(VueClipboard);
Vue.use(VueFeather);
Vue.use(VeeValidate);
Vue.use(VueToastr, {
    defaultPosition: 'toast-top-right',
    defaultType: 'info',
    defaultTimeout: 5000
});

Vue.prototype.$accounts = axios.create({
    baseURL: Vue.prototype.$ACCOUNTS_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'BWS ' + localStorage.getItem("token")
    }
});

Vue.prototype.$accounts.interceptors.request.use((config) => {
    let token = localStorage.getItem("token");
    config.headers.Authorization = `BWS ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});

Vue.prototype.$accounts.interceptors.response.use((res) => {
    return res;
}, function (error) {
    if (error.response && error.response.status === 401) {
        // window.location.href = "/auth/login";
    }
    return Promise.reject(error);
});


Vue.prototype.$identity = axios.create({
    baseURL: Vue.prototype.$IDENTITY_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'BWS ' + localStorage.getItem("token")
    }
});

Vue.prototype.$identity.interceptors.request.use((config) => {
    let token = localStorage.getItem("token");
    config.headers.Authorization = `BWS ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});

Vue.prototype.$identity.interceptors.response.use((res) => {
    return res;
}, function (error) {
    if (error.response && error.response.status === 401) {
         window.location.href = "/auth/login";
    }
    return Promise.reject(error);
});


Vue.prototype.$assetregistry = axios.create({
    baseURL: Vue.prototype.$ASSET_REGISTRY_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'BWS ' + localStorage.getItem("token")
    }
});

Vue.prototype.$assetregistry.interceptors.request.use((config) => {
    let token = localStorage.getItem("token");
    config.headers.Authorization = `BWS ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});

Vue.prototype.$assetregistry.interceptors.response.use((res) => {
    return res;
}, function (error) {
    return Promise.reject(error);
});

Vue.prototype.$provenance = axios.create({
    baseURL: Vue.prototype.$PROVENANCE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'BWS ' + localStorage.getItem("token")
    }
});

Vue.prototype.$provenance.interceptors.request.use((config) => {
    let token = localStorage.getItem("token");
    config.headers.Authorization = `BWS ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});

Vue.prototype.$provenance.interceptors.response.use((res) => {
    return res;
}, function (error) {
    if (error.response && error.response.status === 401) {
        window.location.href = "/auth/login";
    }
    return Promise.reject(error);
});

Vue.filter('unitFix', function (value, precision) {
    if (value === undefined || value === null) return '';
    return Number(value).toFixed(precision);
});

Vue.component('standard-page', StandardPage);
Vue.component('mobile-page', MobilePage);
Vue.component('extrinsic-link', ExtrinsicLink);
Vue.component('data-table', DataTable);
Vue.component('delete-modal', DeleteModal);
Vue.component('confirm-modal', ConfirmModal);
Vue.use(VMdDateRangePicker);


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});

Vue.filter('truncate', function (text, length, suffix) {
    if (text.length <= length) return text;
    return text.substring(0, length) + suffix;
});


Vue.filter('fromNow', function (dateString) {
    if (!dateString) return '';
    return moment(dateString).fromNow();
});
Vue.filter('date', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMMM Do YYYY");
});
Vue.filter('timestamp', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMMM Do YYYY, h:mm:ss a");
});
Vue.filter('long', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMM Do, h:mm a");
});
Vue.filter('shorttimestamp', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMM Do YYYY, h:mm a");
});
Vue.filter('epoch', function (epochString) {
    return moment.unix(epochString).format('MMM Do YYYY');
});
Vue.filter('medium', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("MMM Do YYYY");
});
Vue.filter('timeline', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("DD/MM/YYYY");
});

Vue.filter('datetime', function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format("YYYY MMM DD, h:mm:ss a");
});

Vue.filter('did', function (didString) {
    if (!didString) {
        return '';
    }
    return 'did:bws:' + didString.substring(2, didString.length - 2);
});

Vue.filter('currency', function (currency_id) {
    if (!currency_id) return '';
    let currencies = JSON.parse(localStorage.getItem('currencies'));
    let currency = _.filter(currencies, { currency: currency_id });
    return currency[0] || {};
});

Vue.filter('timezone', function (abbr) {
    if (!abbr) return '';
    let timezone = _.filter(timezones, { abbr: abbr });
    return timezone[0] || {};
});

Vue.filter('value', function (obj, key) {
    if (key && obj) {
        return obj[key];
    } else {
        return '';
    }
});
Vue.filter('hex_format', function (text, length, suffix) {
    if (text && typeof text === 'string') {
        if (text.length <= length) return text;
        return text.substring(0, length) + suffix + text.substring(text.length - length);
    }else {
        return text;
    }
});

Vue.component('pager', {
    // ... options ...
});