<template>
  <div class="modal" id="confirmationmodal">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body tx-center pd-y-20 pd-x-20">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block"></i>
          <h4 class="tx-indigo mg-b-20">Warning!</h4>
          <p v-if="context" class="mg-b-20 mg-x-20">{{`Are you sure you want to delete this ${context}?`}}</p>
          <p v-if="message" class="mg-b-20 mg-x-20">{{message}}</p>
          <button @click="deleteItem()" class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal" type="button">
            Continue
          </button>
        </div>
      </div><!-- modal-content -->
    </div><!-- modal-dialog -->
  </div>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "DeleteModal",
  props: ["service", "context", "url", "table", "message", "deleteFunction", "dontShowSuccessToastr"],
  methods: {
    async deleteItem() {
      if (this.deleteFunction) {
        await this.deleteFunction();
      } else {
        try {
          EventBus.$emit('openLoader');
          if (this.service === 'identity') {
            await this.$identity.delete(`${this.url}`);
          } else if (this.service === 'provenance') {
            await this.$provenance.delete(`${this.url}`);
          } else if (this.service === 'accounts') {
            await this.$accounts.delete(`${this.url}`);
          } else {
            console.log('Error: unknown service');
          }
          this.table.ajax.reload();
          if (!this.dontShowSuccessToastr) {
            this.$toastr.s(`${this.context} Successfully deleted!`, 'Success');
          }
        } catch (e) {
          console.log(e);
          this.$toastr.e(`${this.context} deletion failed`, 'Error');
        } finally {
          EventBus.$emit('closeLoader');
        }
      }
    }
  }
}
</script>

<style scoped>
</style>