<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
}
</script>
<style src="../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.clickable-coll {
  cursor: pointer;
}
.clickable-rows tbody tr:hover,
.clickable_row:hover,
.table-striped tbody tr:nth-of-type(2n + 1).clickable_row:hover,
.table-striped.clickable-rows tbody tr:nth-of-type(2n + 1):hover {
  background-color: #e6efff;
}

/*.router-link-exact-active {*/
/*  font-weight: 900 !important;*/
/*}*/
.router-link-active {
  color: #36bbf4 !important;
}
.router-link-active:hover {
  color: #f46f36 !important;
}

.table-editor-button {
  border: 1px solid gray;
  width: 38px;
  height: 38px;
  margin-left: 5px;
  background-color: white;
  font-size: 17px;
  text-align: center;
  padding: 1px 0px 0px 0px;
}
.table-editor-check {
  color: green;
}
.table-editor-cancel {
  color: red;
  padding: 1px 0px 0px 1px;
}
table.dataTable thead th {
  vertical-align: middle;
}
table.dataTable {
  width: 100% !important;
}

table thead th {
    white-space: nowrap
}

table.dataTable thead th {
    white-space: nowrap
}

table td {
    white-space: nowrap
}

.attested,
.notattested {
  border-radius: 5px;
  padding: 2px 7px;
  color: white;
  font-weight: 500;
}
.attested {
  background-color: green;
}
.notattested {
  background-color: red;
  white-space: nowrap;
}
.edit-fact-container {
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-gap: 15px;
}
.editor {
  height: 38px;
  padding: 0px 10px;
}
.action span {
  white-space: nowrap;
}
.table th,
.table td.action {
  line-height: 2;
}
</style>
