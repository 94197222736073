<template>
  <div>
    <!-- <a id="showLeftMenu" @click="showLeftMenu" class="showLeftMenu"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-compact-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"></path>
      </svg></a> -->
    <div class="row">
      <div class="col-md-6 text-left">
        <h3 v-if="title">{{title}}</h3>
      </div>
      <div class="col-md-6 text-right">
        <slot name="buttons">
        </slot>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-2">
        <div @click="showLeftMenu" class="menu-icon">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="col-xs-10 ">
        <div class="az-content-label mg-b-5 subtitle" v-if="sub_title">{{sub_title}}</div>
      </div>
    </div>
    <slot name="sub-title"></slot>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "standard-page",
  props: ['title', 'sub_title', 'definition', 'status'],
  data() {
    return {
      status_grey: ['Pending'],
      status_blue: ['Created', 'Creating', 'InProgress'],
      status_green: ['Attested', 'Active', 'Completed'],
      status_red: ['InActive']
    };
  },
  methods: {
    showLeftMenu() {
      $('body').addClass('az-content-left-show');
    },
    hideLeftMenu() {
      $('body').removeClass('az-content-body-show');
    }
  }
}
</script>

<style scoped>
.az-content-title {
  margin-bottom: 30px;
}

.status {
  border-radius: 3px !important;
  margin-left: 5px;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 6px;
  color: #fff;
  line-height: 0.8;
  position: relative;
  bottom: 3px;
  letter-spacing: normal;
}

.status_grey {
  background-color: #7987a1;
}

.status_blue {
  background-color: #17a2b8;
}

.status_green {
  background-color: #3bb001;
}

.status_red {
  background-color: #dc3545;
}

.menu-icon {
  padding: 0px 15px;
  cursor: pointer;
}

.menu-icon > div {
  width: 25px;
  height: 4px;
  background-color: black;
  margin: 5px 0;
}
.subtitle {
  margin-top: 10px;
  padding-left: 5px;
}
</style>