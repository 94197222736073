import Vue from 'vue';
import Router from 'vue-router';

import User from '@/models/User';

Vue.use(Router);
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: resolve => require(["@/components/protected/Workspace"], resolve),
            children: [
                {
                    path: "",
                    name: "dashboard",
                    redirect: "/identities"
                },
                {
                    path: "service",
                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "service",
                            component: resolve => require(["@/components/protected/service/Services"], resolve),
                        }
                    ]
                },
                {
                    path: "identities",
                    component: resolve => require(["@/components/protected/identities/IdentityLayout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "identities-home",
                            component: resolve => require(["@/components/protected/identities/Home"], resolve),
                        },
                        {
                            path: "catalogs",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "identities-catalogs",
                                    props: true,
                                    component: resolve => require(["@/components/protected/identities/catalogs/Catalogs"], resolve),
                                },
                                {
                                    path: "new",
                                    name: "new-identities-catalogs",
                                    props: true,
                                    component: resolve => require(["@/components/protected/identities/catalogs/NewCatalog"], resolve),
                                },
                                {
                                    path: ":catalogid",
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "templates",
                                            component: resolve => require(["./components/protected/identities/catalogs/actions/templates/TemplateLayout"], resolve),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "templates",
                                                    props: true,
                                                    component: resolve => require(["./components/protected/identities/catalogs/actions/templates/Templates"], resolve),
                                                },
                                                {
                                                    path: "new",
                                                    name: "new-template",
                                                    props: true,
                                                    component: resolve => require(["./components/protected/identities/catalogs/actions/templates/NewTemplate"], resolve),
                                                },
                                                {
                                                    path: ":templateid/:templatetype",
                                                    component: resolve => require(["./components/protected/identities/catalogs/actions/templates/action/TemplateActionLayout"], resolve),
                                                    children: [
                                                        {
                                                            path: "",
                                                            name: "view-and-edit-template",
                                                            props: true,
                                                            component: resolve => require(["./components/protected/identities/catalogs/actions/templates/action/updateTemplate"], resolve),
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            path: "dids",
                                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "identities-catalogs-dids",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/DIDs"], resolve),
                                                },
                                                {
                                                    path: "new",
                                                    name: "newdid",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/NewDidWithProperty"], resolve),
                                                },
                                                {
                                                    path: "add",
                                                    name: "adddid",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/AddExistingDid"], resolve),
                                                },
                                                {
                                                    path: ":did",
                                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                                    children: [
                                                        {
                                                            path: "",
                                                            name: "viewdid",
                                                            props: true,
                                                            component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/ViewDID"], resolve),
                                                        },
                                                        {
                                                            path: "addrecords",
                                                            name: "addrecords",
                                                            props: true,
                                                            component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/didRecord/AddDidRecord"], resolve),
                                                        },
                                                        {
                                                            path: "addcontrollers",
                                                            name: "addcontrollers",
                                                            props: true,
                                                            component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/didController/AddDidController"], resolve),
                                                        },
                                                        {
                                                            path: "didauthorization",
                                                            name: "didauthorization",
                                                            props: true,
                                                            component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/didAuthorization/DidAuthorization"], resolve),
                                                        },
                                                        {
                                                            path: ":claimid",
                                                            name: "view-claim",
                                                            props: true,
                                                            component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/claim/ViewClaim"], resolve),
                                                        },
                                                        {
                                                            path: ":claimid/viewattestation",
                                                            name: "view-attestation",
                                                            props: true,
                                                            component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/claim/ViewAttestation"], resolve),
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: "claimrequests",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "claim-requests",
                                    component: resolve => require(["@/components/protected/identities/claim-consumers/RequestedDids"], resolve),
                                },
                                {
                                    path: ":catalog/:did",
                                    name: "view-requested-did",
                                    props: true,
                                    component: resolve => require(["@/components/protected/identities/claim-consumers/actions/ViewRequestedDid"], resolve),
                                },
                                {
                                    path: ":catalog/:did/addclaim",
                                    name: "newclaim",
                                    props: true,
                                    component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/claim/NewClaim"], resolve),
                                },
                                {
                                    path: ":did/:claimid",
                                    name: "view-claim-for-consumer",
                                    props: true,
                                    component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/claim/ViewClaim"], resolve),
                                },
                                {
                                    path: ":did/:claimid/viewattestation",
                                    name: "view-attestation-for-consumer",
                                    props: true,
                                    component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/claim/ViewAttestation"], resolve),
                                }
                            ]
                        },
                        {
                            path: "attestationrequests",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "attestation-requests",
                                    component: () => import("@/components/protected/identities/claim-issuers/RequestedAttestations"),
                                },
                                {
                                    path: ":did",
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "",
                                            name: "view-attestation-requests",
                                            props: true,
                                            component: resolve => require(["@/components/protected/identities/claim-issuers/actions/ViewRequestedAttestation"], resolve),
                                        },
                                        {
                                            path: ":did/:claimid",
                                            name: "view-claim-for-issuer",
                                            props: true,
                                            component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/claim/ViewClaim"], resolve),
                                        },
                                        {
                                            path: ":did/:claimid/viewattestation",
                                            name: "view-attestation-for-issuer",
                                            props: true,
                                            component: resolve => require(["@/components/protected/identities/catalogs/actions/dids/actions/claim/ViewAttestation"], resolve),
                                        }
                                    ]
                                }
                            ]
                        },
                    ]
                },
                {
                    path: "assets",
                    component: resolve => require(["@/components/protected/assetregistry/AssetRegistryLayout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "assets-home",
                            component: resolve => import("@/components/protected/assetregistry/Home")
                        },
                        {
                            path: "catalogs",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "assets-catalogs",
                                    props: true,
                                    component: resolve => require(["@/components/protected/assetregistry/catalogs/Catalogs"], resolve),
                                },
                                {
                                    path: ":catalogid/assets",
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "",
                                            name: "assets",
                                            props: true,
                                            component: resolve => require(["@/components/protected/assetregistry/catalogs/actions/assets/Assets"], resolve),
                                        },
                                        {
                                            path: ":assetid",
                                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "viewasset",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/assetregistry/catalogs/actions/assets/actions/Properties"], resolve),
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    path: "provenance",
                    component: resolve => require(["@/components/protected/provenances/ProvenanceLayout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "provenances-home",
                            component: resolve => require(["@/components/protected/provenances/ProvenanceHome"], resolve),
                        },
                        {
                            path: "availableprocess",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "available-processes",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/availableprocess/AvailableProcessDefinitions"], resolve),
                                },
                                {
                                    path: "newprocess",
                                    name: "new-process",
                                    component: resolve => require(["./components/protected/provenances/availableprocess/InitiateProcess"], resolve),
                                },
                                {
                                    path: ":processdefinitionid/:registryid",
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "",
                                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "available-process",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/provenances/availableprocess/actions/process/Processes"], resolve),
                                                },
                                                {
                                                    path: "new",
                                                    name: "new-process",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/provenances/availableprocess/actions/process/NewProcess"], resolve),
                                                }
                                            ]
                                        },
                                        {
                                            path: "steps/:stepid",
                                            name: "view-process-definition-step-by-attestor",
                                            props: true,
                                            component: resolve => require(["@/components/protected/provenances/registries/actions/processdefinitions/actions/steps/actions/ViewProcessDefinitionStep"], resolve),
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            path: "ongoingprocess",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "ongoing-process",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/ongoingprocess/OngoingProcesses"], resolve),
                                },
                                {
                                    path: ":processid/:registryid/:processdefinitionid",
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "qrprint",
                                            name: "process-qr-print",
                                            props: true,
                                            component: resolve => require(["./components/protected/provenances/components/ProcessQR"], resolve),
                                        },
                                        {
                                            path: "steps",
                                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "ongoing-process-steps",
                                                    props: true,
                                                    component: resolve => require(["./components/protected/provenances/ongoingprocess/actions/steps/ProcessStepsWizard"], resolve),
                                                },
                                                {
                                                    path: ":processdefinitionstepid/notfound",
                                                    name: "view-ongoing-future-process-step",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/provenances/ongoingprocess/actions/steps/ViewFutureProcessStep"], resolve),
                                                },
                                                {
                                                    path: ":processstepid/:processdefinitionstepid",
                                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                                    children: [
                                                        {
                                                            path: "",
                                                            name: "view-ongoing-process-step",
                                                            props: true,
                                                            component: resolve => require(["./components/protected/provenances/ongoingprocess/actions/steps/actions/ViewProcessStep"], resolve),
                                                        },
                                                        {
                                                            path: "addattributes",
                                                            name: "add-process-step-attribute",
                                                            props: true,
                                                            component: resolve => require(["@/components/protected/provenances/ongoingprocess/actions/steps/actions/attributes/AddAttributes"], resolve),
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            path: "pendingprocess",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "pending-process",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/pendingprocesses/PendingProcesses"], resolve),
                                },
                            ]
                        },
                        {
                            path: "completeprocess",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "complete-process",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/completeprocesses/CompleteProcesses"], resolve),
                                },
                                {
                                    path: ":processid/:registryid/:processdefinitionid",
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "steps",
                                            name: "complete-process-steps",
                                            props: true,
                                            component: resolve => require(["@/components/protected/provenances/ongoingprocess/actions/steps/ProcessSteps"], resolve),
                                        },
                                        {
                                            path: "steps/:processstepid/:processdefinitionstepid",
                                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "view-complete-process-step",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/provenances/ongoingprocess/actions/steps/actions/ViewProcessStep"], resolve),
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: "registries",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "provenances-registries",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/registries/Registries"], resolve),
                                },
                                {
                                    path: "new",
                                    name: "new-provenances-registries",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/registries/NewRegistry"], resolve),
                                },
                                {
                                    path: ":registryid",
                                    props: true,
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "processes",
                                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                            children: [
                                                {
                                                    path: "",
                                                    name: "processdefinitions",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/provenances/registries/actions/processdefinitions/ProcessDefinition"], resolve),
                                                },
                                                {
                                                    path: "new",
                                                    name: "new-process-definitions",
                                                    props: true,
                                                    component: resolve => require(["@/components/protected/provenances/registries/actions/processdefinitions/NewProcessDefinition"], resolve),
                                                },
                                                {
                                                    path: ":processdefinitionid",
                                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                                    children: [
                                                        {
                                                            path: "process",
                                                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                                            children: [
                                                                {
                                                                    path: "",
                                                                    name: "process",
                                                                    props: true,
                                                                    component: resolve => require(["./components/protected/provenances/registries/actions/processdefinitions/actions/process/Processes"], resolve),
                                                                },
                                                                {
                                                                    path: ":processid/steps",
                                                                    name: "process-steps",
                                                                    props: true,
                                                                    component: resolve => require(["@/components/protected/provenances/ongoingprocess/actions/steps/ProcessSteps"], resolve),
                                                                },
                                                                {
                                                                    path: ":processid/:processdefinitionstepid/view",
                                                                    name: "view-future-process-step",
                                                                    props: true,
                                                                    component: resolve => require(["@/components/protected/provenances/ongoingprocess/actions/steps/ViewFutureProcessStep"], resolve),
                                                                },
                                                                {
                                                                    path: ":processid/steps/:processstepid/:processdefinitionstepid/view",
                                                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                                                    children: [
                                                                        {
                                                                            path: "",
                                                                            name: "view-process-step",
                                                                            props: true,
                                                                            component: resolve => require(["@/components/protected/provenances/ongoingprocess/actions/steps/actions/ViewProcessStep"], resolve),
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            path: "steps",
                                                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                                            children: [
                                                                {
                                                                    path: "",
                                                                    name: "process-definition-steps",
                                                                    props: true,
                                                                    component: resolve => require(["@/components/protected/provenances/registries/actions/processdefinitions/actions/steps/ProcessDefinitionSteps"], resolve),
                                                                },
                                                                {
                                                                    path: "new",
                                                                    name: "new-process-definition-steps",
                                                                    props: true,
                                                                    component: resolve => require(["@/components/protected/provenances/registries/actions/processdefinitions/actions/steps/NewProcessDefinitionStep"], resolve),
                                                                },
                                                                {
                                                                    path: ":stepid",
                                                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                                                    children: [
                                                                        {
                                                                            path: "",
                                                                            name: "view-process-definition-step",
                                                                            props: true,
                                                                            component: resolve => require(["@/components/protected/provenances/registries/actions/processdefinitions/actions/steps/actions/ViewProcessDefinitionStep"], resolve),
                                                                        },
                                                                        {
                                                                            path: "edit",
                                                                            name: "edit-process-definition-step",
                                                                            props: true,
                                                                            component: resolve => require(["@/components/protected/provenances/registries/actions/processdefinitions/actions/steps/actions/EditProcessDefinitionStep"], resolve),
                                                                        },
                                                                        {
                                                                            path: "addattestor",
                                                                            name: "add-attestor",
                                                                            props: true,
                                                                            component: resolve => require(["@/components/protected/provenances/registries/actions/processdefinitions/actions/steps/actions/AddAttestor"], resolve),
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: "trackandtrace",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "step1",
                                    name: "pickup",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/trackandtrace/PickUp"], resolve),
                                },
                                {
                                    path: "step2",
                                    name: "receive",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/trackandtrace/Receive"], resolve),
                                },
                                {
                                    path: "step3",
                                    name: "dispatch",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/trackandtrace/Dispatch"], resolve),
                                },
                                {
                                    path: "step4",
                                    name: "delivery",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/trackandtrace/Delivery"], resolve),
                                },
                                {
                                    path: "summary",
                                    name: "summary",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/trackandtrace/Summary"], resolve),
                                },
                                {
                                    path: ":crateid",
                                    name: "trackandtraceendpoint",
                                    props: true,
                                    component: resolve => require(["@/components/protected/provenances/trackandtrace/TrackAndTrace"], resolve),
                                }
                            ]
                        }
                    ]
                },
                {
                    path: "admin",
                    component: resolve => require(["@/components/protected/admin/Layout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "admin-home",
                            component: resolve => require(["@/components/protected/admin/Home"], resolve),
                        },
                        {
                            path: "orgs",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "organizations",
                                    component: resolve => require(["@/components/protected/admin/orgs/Orgs"], resolve),
                                },
                                {
                                    path: ":orgid",
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "",
                                            props: true,
                                            name: "vieworg",
                                            component: resolve => require(["@/components/protected/admin/orgs/actions/ViewOrg"], resolve),
                                        },
                                        {
                                            path: "userroles/:userid/",
                                            props: true,
                                            name: "userroles",
                                            component: resolve => require(["@/components/protected/admin/orgs/actions/UserRoles"], resolve),
                                        },
                                        {
                                            path: "subscribe",
                                            props: true,
                                            name: "subscribe",
                                            component: resolve => require(["@/components/protected/admin/orgs/actions/AddService"], resolve),
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: "currencies",
                            name: "currencies",
                            component: resolve => require(["@/components/protected/admin/Currencies"], resolve),
                        },
                        {
                            path: "units",
                            name: "units",
                            component: resolve => require(["@/components/protected/admin/Units"], resolve),
                        },
                        {
                            path: "taxes",
                            name: "taxes",
                            component: resolve => require(["@/components/protected/admin/Taxes"], resolve),
                        },
                        {
                            path: "taxes/:taxid/rates",
                            name: "rates",
                            props: true,
                            component: resolve => require(["@/components/protected/admin/Rates"], resolve),
                        },
                        {
                            path: "permissions",
                            name: "permissions",
                            component: resolve => require(["@/components/protected/admin/Permissions"], resolve),
                        },
                        {
                            path: "roles",
                            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                            children: [
                                {
                                    path: "",
                                    name: "roles",
                                    component: resolve => require(["@/components/protected/admin/roles/Roles"], resolve),
                                }, {
                                    path: ":serviceid/:roleid",
                                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                                    children: [
                                        {
                                            path: "permissions",
                                            name: "role-permissions",
                                            props: true,
                                            component: resolve => require(["@/components/protected/admin/roles/RolePermissions"], resolve),
                                        },
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    path: "myaccount",
                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "myaccount",
                            component: () => import("@/components/protected/myaccount/ViewProfile"),
                        },
                        {
                            path: "edit",
                            name: "accedit",
                            component: () => import("@/components/protected/myaccount/Edit"),
                        },
                        {
                            path: "changepassword",
                            name: "changepassword",
                            component: () => import("@/components/protected/myaccount/ChangePassword"),
                        }
                    ]
                },
                {
                    path: "myorg",
                    component: resolve => require(["@/components/protected/BlankLayout"], resolve),
                    children: [
                        {
                            path: "",
                            name: "myorg",
                            component: () => import("@/components/protected/myorg/ViewOrganization"),
                        },
                        {
                            path: "edit",
                            name: "orgedit",
                            component: () => import("@/components/protected/myorg/Edit"),
                        },
                        {
                            path: "defaultsedit",
                            name: "defaultsedit",
                            component: () => import("@/components/protected/myorg/EditDefaults"),
                        }
                    ]
                }
            ]
        },
        {
            path: '/auth',
            component: () => import("./components/auth/AuthLayout"),
            children: [
                {
                    path: 'login',
                    name: "login",
                    meta: {
                        unsecured: true
                    },
                    component: () => import("./components/auth/Login"),
                },
                {
                    path: 'signup',
                    name: "signup",
                    meta: {
                        unsecured: true
                    },
                    component: () => import("./components/auth/Signup"),
                },
                {
                    path: 'forgotpassword',
                    name: "forgotpassword",
                    meta: {
                        unsecured: true
                    },
                    component: () => import("./components/auth/ForgotPassword"),
                },
            ]
        },
        {
            path: '/payment',
            component: resolve => require(["@/components/protected/BlankLayout"], resolve),
            children: [
                {
                    path: "success",
                    name: "paymentsuccess",
                    component: () => import("@/components/protected/stripe/SuccessUrl.vue"),
                },
                {
                    path: "failed",
                    name: "paymentFailure",
                    component: () => import("@/components/protected/stripe/FailedUrl.vue"),
                }
            ]
        },
        {
            path: '/forgot_password_reset/:token',
            name: "resetpassword",
            meta: {
                unsecured: true
            },
            props: true,
            component: resolve => require(["./components/auth/ResetPassword"], resolve)
        }
    ]
});

router.beforeEach((to, from, next) => {
    if(!to.meta.unsecured) {
        let user = User.from(localStorage.token);
        if(user) {
            next();
        } else {
            next({
                name: 'login'
            });
        }
    }
    next();
});

export default router;

