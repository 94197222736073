<template>
  <td v-if="extrinsic" :title="extrinsic">
    <a :href="$BLOCK_EXPLORER+'/tx/'+extrinsic" target="_blank" @click.stop>
      <span class="fa fa-link"></span> {{extrinsic| truncate(10, '.....')}}
    </a>
  </td>
  <td v-else></td>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "extrinsic-link",
  props: ['extrinsic']
};
</script>