<template>
    <div class="az-content-body pd-lg-l-40 d-flex flex-column">
    <!--        BREADCRUMB      -->
      <div class="az-content-breadcrumb d-none d-md-block">
        <slot name="breadcrumb"></slot>
        <slot v-if="this.$slots.backBreadcrumb" name="back-breadcrumb"></slot>
        <div v-else class="mg-l-auto float-right d-none d-xl-block">
          <a @click="$router.go(-1)" href="javascript:void(0)"><i class="fa fa-arrow-left" />Back</a>
        </div>
      </div>
    <!--        TITLE       -->
    <div class="row row-sm">
      <div class="col-6 text-left">
        <h4 class="az-content-title" v-if="title">{{title}}
          <span class="status status_grey" v-if="status && status_grey.includes(status)">{{status}}</span>
          <span class="status status_green" v-if="status && status_green.includes(status)">{{status}}</span>
          <span class="status status_red" v-if="status && status_red.includes(status)">{{status}}</span>
          <span class="status status_blue" v-if="status && status_blue.includes(status)">{{status}}</span>
        </h4>
      </div>
      <div class="col-6 text-right">
        <slot name="buttons"></slot>
      </div>
    </div>
    <div class="az-content-label mg-b-5" v-if="sub_title">{{sub_title}}</div>
    <slot name="sub-title"></slot>
    <p class="mg-b-10" v-html="definition"></p>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "standard-page",
  props: ['title', 'sub_title', 'definition', 'status'],
  data() {
    return {
      status_grey: ['Pending'],
      status_blue: ['Created', 'Creating', 'InProgress'],
      status_green: ['Attested', 'Active', 'Completed'],
      status_red: ['InActive']
    };
  }
}
</script>

<style scoped>
.az-content-title {
  margin-bottom: 30px;
}

.status {
  border-radius: 3px !important;
  margin-left: 5px;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 6px;
  color: #fff;
  line-height: 0.8;
  position: relative;
  bottom: 3px;
  letter-spacing: normal;
}

.status_grey {
  background-color: #7987a1;
}

.status_blue {
  background-color: #17a2b8;
}

.status_green {
  background-color: #3bb001;
}

.status_red {
  background-color: #dc3545;
}
</style>